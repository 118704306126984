import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { ISession } from './session';
import { GenericService } from './genericService';
import { Config } from '../config/config';

@Injectable()
export class LoginService extends GenericService {
    private _url = Config.getEnvironmentVariable('endPoint');
    private _loginUrl = this._url + '/vgmWeb/api/login';
    // private _loginUrl = Config.getEnvironmentVariable('endPoint') + '/webTermint/api/login'
    session: ISession = null;


    constructor(_http: Http) {
        super(_http);
    }



    login(payload): Observable<ISession> {
        return this._http.post(this._loginUrl, JSON.stringify(payload))
            .map((response: Response) => <ISession>response.json());
    }


}
