import { Routes, RouterModule } from '@angular/router';
import { VermasListComponent } from './vermas/vermas-list.component';
import { WelcomeComponent } from './home/welcome.component';
import { VermasDetailComponent } from './vermas/vermas-detail.component';
import { VermasViewComponent } from './vermas/vermas-view.component';
import { DocumentationComponent } from './shared/documentacion.component';

const appRoutes: Routes = [
  { path: '', component: WelcomeComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'vermas', component: VermasListComponent },
  { path: 'detail/:id', component: VermasDetailComponent },
  { path: 'view/:id', component: VermasViewComponent },
  { path: 'docu/:id', component: DocumentationComponent }

];

export const AppRoutingModule = RouterModule.forRoot(appRoutes);
