import { ModuleWithProviders, NgModule,Optional, SkipSelf } from '@angular/core';
import { CommonModule }       from '@angular/common';
import { ValidacionesContenedorService}     from './services/validaciones/validacionesContenedor.service';
import {LocaleDate} from './pipes/localedate.pipe';

@NgModule({
  imports:      [ CommonModule ],
  declarations: [ LocaleDate ],
  exports:      [ LocaleDate],
  providers:    [ ValidacionesContenedorService ] 
 
})

export class PorticCoreAngularModule { }