import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { IUpdateResponse } from './updateResponse';
import { IVermas, ListVermas } from './vermas';
import { GenericService } from '../shared/genericService';
import 'rxjs/add/operator/map';
import { Config } from '../config/config';
import { Tracking } from '../shared/tracking';
import { IRespuestaTerminal } from './respuestaterminal';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class VermasService extends GenericService {
    private _url = Config.getEnvironmentVariable('endPoint');
    private _vermasEliminaUrl = this._url + '/vgmWeb/api/eliminaVermas';

    private _vermasUrl = this._url + '/vgmWeb/api/vermasQuery';
    private _vermasActualizaUrl = this._url + '/vgmWeb/api/vermasUpdate';
    private _vermasCreaUrl = this._url + '/vgmWeb/api/vermasCreate';
    private _vermasSendUrl = this._url + '/vgmWeb/api/vermasSend';
    private _getTermEpeso = this._url + '/vgmWeb/api/getTermEpeso';
    private _getTermEpesoSel = this._url + '/vgmWeb/api/getTermEpesoSel';
    private _vermasLoadOneUrl = this._url + '/vgmWeb/api/vermasQueryOne';
    private _vermasCancelaUrl = this._url + '/vgmWeb/api/vermasCancela';
    private _vermasRecuperaTerminalUrl = this._url + '/vgmWeb/api/recuperaTerminal';

    // private _vermasUrl = './app/api/verified/vermas.json';
    // private _vermasUrl = './app/api/verified/vermas.json';
    constructor(_http: Http) {
        super(_http);
    }

    getAllVermas(payLoad): Observable<ListVermas> {
        return this._http.post(this._vermasUrl, JSON.stringify(payLoad))
            .map((response: Response) => <ListVermas>response.json());

    }

    /*getAllVermas(): Observable<ListVermas> {
        return this._http.get(this._vermasUrl)
            .map((response: Response) => <ListVermas>response.json())
            .catch(this.handleError)
    }*/
    getVermas(payLoad): Observable<IVermas> {
        return this._http.post(this._vermasLoadOneUrl, JSON.stringify(payLoad))
            .map((response: Response) => <IVermas>response.json());

    }
    /* getVermasPendientes ():Observable<ListVermas>{
         return this.getAllVermas()
             .map((vermas:ListVermas) => vermas.lista.filter(p => p.Estado === 'Recibido'));
     }

     getVermasFinalizados():Observable<ListVermas>{
       return this.getAllVermas()
             .map((vermas:ListVermas) => vermas.lista.filter(p => p.Estado === 'Finalizado'));
     }
     */
    actualizaVermas(vermas: IVermas): Observable<IUpdateResponse> {
        return this._http.post(this._vermasActualizaUrl, JSON.stringify(this.eliminarEspacios(vermas)))
            .map((response: Response) => <IUpdateResponse>response.json());

    }

    crearVermas(vermas: IVermas): Observable<IUpdateResponse> {
        return this._http.post(this._vermasCreaUrl, JSON.stringify(this.eliminarEspacios(vermas)))
            .map((response: Response) => <IUpdateResponse>response.json());


    }


    getTermEpeso(): Observable<Tracking> {
        return this._http.post(this._getTermEpeso, null)
            .map((response: Response) => <Tracking>response.json());

    }

    getTermEpesoSel(nif: string): Observable<Tracking> {
        return this._http.post(this._getTermEpesoSel, JSON.stringify(nif))
            .map((response: Response) => <Tracking>response.json());

    }

    sendVermas(vermas: IVermas): Observable<Tracking> {
        return this._http.post(this._vermasSendUrl, JSON.stringify(this.eliminarEspacios(vermas)))
            .map((response: Response) => <Tracking>response.json());

    }
    cancelarVermas(vermas: IVermas): Observable<Tracking> {
        return this._http.post(this._vermasCancelaUrl, JSON.stringify(this.eliminarEspacios(vermas)))
            .map((response: Response) => <Tracking>response.json());

    }
    eliminar(vermas: IVermas): Observable<IUpdateResponse> {
        return this._http.post(this._vermasEliminaUrl, JSON.stringify(this.eliminarEspacios(vermas)))
            .map((response: Response) => <IUpdateResponse>response.json());

    }
    calcularTerminal(vermas: IVermas): Observable<IRespuestaTerminal> {
        return this._http.post(this._vermasRecuperaTerminalUrl, JSON.stringify(this.eliminarEspacios(vermas)))
            .map((response: Response) => <IRespuestaTerminal>response.json());

    }

    eliminarEspacios(vermas: IVermas): IVermas {

        try {
            if (vermas.containerId != undefined && vermas.containerId != null) { vermas.containerId = vermas.containerId.trim(); }
            if (vermas.containerReference != undefined && vermas.containerReference != null) { vermas.containerReference = vermas.containerReference.trim(); }
            if (vermas.Consignatario != undefined && vermas.Consignatario != null) { vermas.Consignatario = vermas.Consignatario.trim(); }
            if (vermas.Booking != undefined && vermas.Booking != null) { vermas.Booking = vermas.Booking.trim(); }
        } catch (excep) {
            console.log('Error al parsear datos' + excep);
        }

        return vermas;
    }

}

