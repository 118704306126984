import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { LoginService } from './shared/login.service';
import { SpinnerService } from './shared/spinner.service';
import { VermasService } from './vermas/vermas.service';
import { ComunService } from './shared/comun.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.route';
import { WelcomeComponent } from './home/welcome.component';
import { VermasListComponent } from './vermas/vermas-list.component';
import { VermasDetailComponent } from './vermas/vermas-detail.component';
import { VermasViewComponent } from './vermas/vermas-view.component';
import { DocumentationComponent } from './shared/documentacion.component';
import { ReceptorComponent } from './shared/receptor.component';
import { DialogModule, CalendarModule, GrowlModule } from 'primeng/primeng';
import { DataGridModule, DataTableModule, SharedModule, RadioButtonModule } from 'primeng/primeng';
import { LocaleDate } from './pipes/localedate.pipe';
import { PorticCoreAngularModule } from './modules/porticCoreAngularModule/porticCoreAngularModule.module';

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    VermasListComponent,
    DocumentationComponent,
    VermasDetailComponent,
    VermasViewComponent,
    ReceptorComponent,
    LocaleDate

  ],
  imports: [

    BrowserModule,
    FormsModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DialogModule,
    DataGridModule,
    DataTableModule,
    SharedModule,
    CalendarModule,
    GrowlModule,
    RadioButtonModule,
    PorticCoreAngularModule
  ],
  providers: [
    LoginService,
    SpinnerService,
    ComunService,
    VermasService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
