import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { IEmisor, ListEsmisor } from './emisor';
import { Payload } from './payload';
import 'rxjs/add/operator/map';
import { IListaDocumentacion, IDocumentacion } from './documentacion';
import { GenericService } from './genericService';
import { Config } from '../config/config'

@Injectable()
export class ComunService extends GenericService {
    private _url = Config.getEnvironmentVariable('endPoint');
    private _listaReceptoresUrl = this._url + '/vgmWeb/api/listaReceptores';
    // private _listaReceptoresUrl = './app/api/verified/terminales.json';
    // private _documentacionUrl = './app/api/verified/documentacion.json';
    private _documentacionUrl = this._url + '/vgmWeb/api/documentacion';
    // private _loginUrl = Config.getEnvironmentVariable('endPoint') + '/webTermint/api/login'
    // session: ISession = null;
    private mensajePendiente: string;

    constructor(_http: Http) {
        super(_http);
    }

    /*obtenerReceptores(payload): Observable<ListEsmisor>{
          return this._http.get(this._listaReceptoresUrl)
              .map((response: Response) => <ListEsmisor>response.json())
              .catch(this.handleError)
      }*/
    obtenerReceptores(payload): Observable<ListEsmisor> {
        return this._http.post(this._listaReceptoresUrl, JSON.stringify(payload))
            .map((response: Response) => <ListEsmisor>response.json())

    }
    recuperarDocumentacion(payload): Observable<IListaDocumentacion> {


        return this._http.post(this._documentacionUrl, JSON.stringify(payload))
            .map((response: Response) => <IListaDocumentacion>response.json())

    }
    /*recuperarDocumentacion(payload): Observable<IListaDocumentacion>{
        return this._http.get(this._documentacionUrl)
            .map((response: Response) => <IListaDocumentacion>response.json())
            .catch(this.handleError)
    }*/
    ponerMensaje(mensaje: string) {
        this.mensajePendiente = mensaje;
    }
    limpiarMensaje() {
        this.mensajePendiente = null;
    }
    obtenerMensaje(): string {
        return this.mensajePendiente;
    }
}