import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Payload } from '../shared/payload';
import { LoginService } from '../shared/login.service';
import { SpinnerService } from '../shared/spinner.service';

@Component({
  templateUrl: 'welcome.component.html'
})
export class WelcomeComponent implements OnInit {

  public usuario = '';
  public password = '';
  public pageTitle = 'Incio de Sesión';
  public mensajeError = '';
  public display = false;
  // tslint:disable-next-line: max-line-length
  constructor(private _loginService: LoginService, private router: Router, private _spinnerService: SpinnerService) { }
  login() {
    this._spinnerService.start();
    // tslint:disable-next-line: prefer-const
    let payload = new Payload<string>();
    payload['username'] = this.usuario;
    payload['password'] = this.password;

    this._loginService.login(payload)
      .subscribe(
        // TODO: Check with secutity service
        session => {
          if (session.codiError >= 0 && session.token) {
            this._loginService.session = session;
            this.loadMasterData();
            this.router.navigate(['/vermas']);
          } else {
            this.mensajeError = session.missatgeLlargError;
            this.display = true;
            // TODO avisar que no s'ha autenticat correctament
          }
        },
        error => this.mensajeError = error,
        () => this._spinnerService.stop()
      );
  }
  loadMasterData() {
    // tslint:disable-next-line: prefer-const
    let payload = new Payload<string>();
    payload['usuariSessio'] = this._loginService.session.usuari;
    payload['nifSessio'] = this._loginService.session.nif;
    payload['paisSessio'] = this._loginService.session.pais;
    payload['token'] = this._loginService.session.token;
  }
  ngOnInit() {
    if (null != this._loginService.session && null != this._loginService.session.usuari) {
      this.router.navigate(['/vermas']);
    }

  }
  aceptar(): void {
    this.mensajeError = '';
    this.display = false;
  }



}
