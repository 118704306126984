import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IEmisor } from '../shared/emisor';
import { IVermas } from '../vermas/vermas';
import { VermasService } from '../vermas/vermas.service';
import { LoginService } from '../shared/login.service';
import { Payload } from '../shared/payload';
import { ComunService } from '../shared/comun.service';
import { Message } from 'primeng/primeng';
import { IUpdateResponse } from './updateResponse';
import { Tracking } from '../shared/tracking';
import { SpinnerService } from '../shared/spinner.service';
import { ValidacionesContenedorService } from '../modules/porticCoreAngularModule/services/validaciones/validacionesContenedor.service';
import { stringify } from 'querystring';

@Component({
    templateUrl: 'vermas-detail.component.html'


})
export class VermasDetailComponent implements OnInit {
    terminalS: string;
    terminalR: string;
    nifTerminalR: string;
    displayDialogTerminal: Boolean = false;
    errTerm: Boolean = false;
    avisoTerFerr: Boolean = false;
    accionTerFerr: string;
    terminalevermas: any[] = [];
    terminalSelect: any[] = [];
    pageTitle = 'Detalle del Peso Verificado';
    msgs: Message[] = [];
    vermas: IVermas;
    terminalRespuesta: any;
    track: Tracking;
    errorMessage: string;
    mensajeConfirmacion: string;
    mensajeGrabacion: string;
    updateResponse: IUpdateResponse;
    view = 'L';
    mostra = false;
    esEmisorOConsignatario: number;
    display = false;
    display1 = false;
    display2 = false;
    displayNoTerminal = false;
    displayMatriculaNoValida = false;
    acciion = '';
    modo = '';
    mensaje: string;
    mensajeContenedorNoValido: string;
    mensajeValidaciones: string;
    enviado = false;
    modal = 'modal';
    private sub: any;
    accion = '';
    es: any;
    fechaHora: Date;
    comprobarMatricula = true;

    constructor(private _vermasService: VermasService,
        private _router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService,
        private router: Router,
        private _spinnerService: SpinnerService,
        private _comunService: ComunService,
        private _validacionesContenedorService: ValidacionesContenedorService
    ) {
    }

    ngOnInit() {
        // ---- primeng calendari en castella
        this.es = {
            closeText: 'Cerrar',
            prevText: '<Ant',
            nextText: 'Sig>',
            currentText: 'Hoy',
            monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
                'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun',
                'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
            dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            weekHeader: 'Sm',
            dateFormat: 'dd/mm/yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        if (!this.vermas) {
            this.modal = 'modal';
            if ((null === this._loginService.session) || (null === this._loginService.session.usuari)) {
                this.router.navigate(['/welcome']);
            } else {
                this.sub = this.route.params.subscribe(params => {
                    const id = +params['id']; // (+) converts string 'id' to a number
                    if (id === -1) {
                        this.view = 'N';
                        this.vermas = new IVermas();
                        this.vermas.MetodoPesado = 'SM1';
                        this.vermas.Function = '9';
                    } else {
                        this.view = 'E';
                        this.getVermasOne(id);
                    }
                }
                );
            }

        }
        this._vermasService.getTermEpeso().subscribe(terminales => {
            this.terminalRespuesta = [];
            this.terminalRespuesta = terminales;
            this.terminalevermas.push({ 'name': 'Seleccione una terminal', 'id': undefined });
            this.terminalRespuesta.map(a => this.terminalevermas.push({ 'name': a.comercialName, 'id': a.NIF }));
            this.terminalSelect = this.terminalevermas;
            if (this.vermas && this.vermas.nombreTerminal) {
                const found = this.terminalevermas.find(element => element.name === this.vermas.nombreTerminal);
                if (!found) {
                    this.terminalevermas.push({ 'name': this.vermas.nombreTerminal, 'id': this.vermas.nifTerminal });
                }
            }
        });
    }
    nomTerminal() {
        const e = (document.getElementById('nifTerminal')) as HTMLSelectElement;
        const sel = e.selectedIndex;
        const opt = e.options[sel];
        this.vermas.nombreTerminal = opt.text;
    }
    onChange() {
        if (this.comprobarRequeridosTerminal()) {
            this.vermas.nifSessio = this._loginService.session.nif;
            this.vermas.paisSessio = this._loginService.session.pais;
            this.vermas.nomEmpresa = this._loginService.session.nomEmpresa;
            this.vermas.token = this._loginService.session.token;
            this._vermasService.calcularTerminal(this.vermas)
                .subscribe(
                    respuesta => {
                        if (respuesta.nifterminal === undefined) {
                            this.errTerm = true;
                        } else {
                            const e = (document.getElementById('nifTerminal')) as HTMLSelectElement;
                            const sel = e.selectedIndex;
                            const opt = e.options[sel];
                            const found = this.terminalevermas.find(element => element.name === respuesta.nomterminal);
                            if (!found) {
                                this.terminalevermas.push({ 'name': respuesta.nomterminal, 'id': respuesta.nifterminal });
                            }
                            if (sel === 0) {
                                this.vermas.nombreTerminal = respuesta.nomterminal;
                                this.vermas.nifTerminal = respuesta.nifterminal;
                            } else if (this.vermas.nifTerminal !== respuesta.nifterminal) {
                                this.terminalS = opt.text;
                                this.terminalR = respuesta.nomterminal;
                                this.nifTerminalR = respuesta.nifterminal;
                                this.displayDialogTerminal = true;
                            }
                        }
                    },
                    error => this.errorMessage = <any>error);
        }
    }

    getVermasOne(id: number) {
        if ((null == this._loginService.session) || (null == this._loginService.session.usuari)) {
            this.router.navigate(['/welcome']);
        } else {
            const payload = new Payload<string>();
            payload['usuariSessio'] = this._loginService.session.usuari;
            payload['nifSessio'] = this._loginService.session.nif;
            payload['paisSessio'] = this._loginService.session.pais;
            payload['token'] = this._loginService.session.token;
            payload['id'] = '' + id;
            payload['pagina'] = '1';
            this._vermasService.getVermas(payload)
                .subscribe(
                    vermas => {
                        this.vermas = vermas;
                        if (vermas.FechaHora !== undefined && vermas.FechaHora != null) {

                            this.fechaHora = new Date(vermas.FechaHora);
                            console.log('Fecha de la consulta' + this.fechaHora);

                        }
                        if ((vermas.errors) && (vermas.errors.length > 0)) {
                            this.mensaje = `No ha sido posible recuperar el: ${vermas.errors}`;
                            this.display1 = true;
                        }
                    },
                    error => this.errorMessage = <any>error);
        }
    }

    onBack(): void {
        this._router.navigate(['/vermas']);
    }
    edit(): void {
        if (this.view === 'L') {
            this.view = 'E';
        } else {
            this.view = 'L';
        }
    }
    show() {

        this.display1 = true;
    }
    volverAtras(event): void {
        this.onBack();
    }
    aceptar(): void {
        if (this.acciion === 'EN') {
            if (this.modo === 'EN') {
                this.modal = '';
                this.display2 = false;


            } else if (this.modo === 'NE') {
                this.display2 = false;

                this.vermas = new IVermas();
                this.vermas.MetodoPesado = 'SM1';
                this.vermas.Function = '9';
            }

        } else if (this.acciion = 'GA') {
            this.display1 = false;
        }
        this.display1 = false;
        this.display = false;

        // if(this.enviado && this.view=='N') this.vermas = new IVermas();
    }

    comprobarRequeridosTerminal(): boolean {
        let datosRequeridos = true;
        if ((null == this.vermas.containerId) || (this.vermas.containerId === '') || (this.vermas.containerId.length < 11)) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + 'matrícula ';
        }
        if ((null == this.vermas.Booking) || (this.vermas.Booking === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + 'Booking ';
        }

        if ((null == this.vermas.nifConsignatario) || (this.vermas.nifConsignatario === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + 'Nif de consignatario ';
        }
        return datosRequeridos;
    }

    comprobarRequeridos(): boolean {

        let datosRequeridos = true;
        let datosNoValidos = false;

        this.mensaje = '<h3> Faltan datos requeridos: </h3><ul>';
        this.mensajeValidaciones = '<h3> Datos incorrectos: </h3><ul>';

        if ((null == this.vermas.containerId) || (this.vermas.containerId === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>matrícula</li>';
        } else {
            if (this._validacionesContenedorService.superaLongitudMaximaMatriculaContenedorPortic(this.vermas.containerId)) {
                datosNoValidos = true;
                this.mensajeValidaciones = this.mensajeValidaciones + '<li>Matrícula excede long máxima 15 </li>';
            }
        }
        if ((null == this.vermas.Booking) || (this.vermas.Booking === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Booking</li>';
        } else {
            if (this._validacionesContenedorService.superaLongitudMaximaBookingPortic(this.vermas.Booking)) {
                datosNoValidos = true;
                this.mensajeValidaciones = this.mensajeValidaciones + '<li>Booking excede long máxima 35 </li>';
            }
        }

        // si hay una referencia validamos que no supere la longitud máxima pero este no es un dato requerido
        if ((null !== this.vermas.containerReference) && (this.vermas.containerReference !== '') &&
            (this.vermas.containerReference !== undefined)) {
            if (this._validacionesContenedorService.superaLongitudMaximaReferenciaContenedorPortic(this.vermas.containerReference)) {
                datosNoValidos = true;
                this.mensajeValidaciones = this.mensajeValidaciones + '<li>Referencia contenedor excede long máxima 70 </li>';
            }
        }

        if ((null == this.vermas.nifSolicitante) || (this.vermas.nifSolicitante === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Nif de solicitante</li>';
        }
        if ((null == this.vermas.nifConsignatario) || (this.vermas.nifConsignatario === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Nif de consignatario</li>';
        }
        if ((null == this.vermas.MetodoPesado) || (this.vermas.MetodoPesado === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Método de pesado</li>';
        }
        if ((null == this.vermas.Peso) || (this.vermas.Peso === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Peso verificado</li>';
        }
        if ((null == this.fechaHora) || (this.fechaHora === undefined)) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Fecha de pesaje</li>';
        } else {
            this.vermas.FechaHora = this.fechaHora.toISOString();
        }

        if ((null == this.vermas.Persona) || (this.vermas.Persona === '')) {
            datosRequeridos = false;
            this.mensaje = this.mensaje + '<li>Persona de contacto </li>';
        }

        if (datosNoValidos) {
            if (datosRequeridos) {
                this.mensaje = this.mensajeValidaciones + '</ul><br/>';
                datosRequeridos = false;
                // si solo hay errores de validaciones mostramos estos errores pero marcamos datosRequeridos a false para que
                // se muestre ventana dialogo
            } else {
                this.mensaje = this.mensajeValidaciones + '</ul><br/>' + this.mensaje + '</ul>';
            }

        }

        return datosRequeridos;
    }
    guardar(comprobar: boolean): void {
        this.accion = 'GUARDAR';
        // tslint:disable-next-line: max-line-length
        if (this.comprobarMatricula === false || this._validacionesContenedorService.esDigitoControlContenedorCorrecto(this.vermas.containerId)) {
            this.comprobarMatricula = true; // actualizamos para nuevas acciones
            // tslint:disable-next-line: triple-equals
            if (comprobar == false || (this.vermas.nifTerminal != null && this.vermas.nifTerminal != '')) {
                this._spinnerService.start();
                if ((null === this._loginService.session) || (null === this._loginService.session.usuari)) {
                    this.router.navigate(['/Welcome']);
                } else {
                    if (this.comprobarRequeridos()) {
                        this.vermas.nifSessio = this._loginService.session.nif;
                        this.vermas.paisSessio = this._loginService.session.pais;
                        this._vermasService.actualizaVermas(this.vermas)
                            .subscribe(
                                updateResponse => {
                                    if (null == updateResponse.id || (updateResponse.errors != null) &&
                                        (updateResponse.errors.length > 0)) {
                                        this.mensaje = `No ha sido posible el grabado: ${updateResponse.errors}`;
                                        this.display1 = true;
                                    } else {
                                        this.mensajeGrabacion = `Grabado correctamente:`;
                                        this.display = true;
                                        this.enviado = true;
                                        //  this._router.navigate(['Vermas']);
                                    }
                                    this._spinnerService.stop();
                                }, error => this.errorMessage = <any>error,
                                () => this._spinnerService.stop());
                    } else {
                        this.show();
                        this._spinnerService.stop();
                    }
                }

            } else {
                this._spinnerService.stop();
                this.displayNoTerminal = true;
            }
        } else { // el digigo de la matricula no es valido

            if ((null == this.vermas.containerId) || (this.vermas.containerId === '') || (this.vermas.containerId === undefined)) {
                this.mensaje = 'Faltan datos requeridos: matricula';
                this.show();
            } else {
                this.mensajeContenedorNoValido = ' El digito de control ' +
                    this.vermas.containerId.substring(this.vermas.containerId.length - 1) +
                    ' , según estándar ISO 6346, para este número de contenedor ' +
                    this.vermas.containerId.substring(0, this.vermas.containerId.length - 1) + ' no es válido </br>¿Desea continuar?';
                this.displayMatriculaNoValida = true;
            }
        }

    }
    crear(comprobar: boolean): void {

        this.accion = 'CREAR';
        // tslint:disable-next-line: max-line-length
        if (this.comprobarMatricula === false || this._validacionesContenedorService.esDigitoControlContenedorCorrecto(this.vermas.containerId)) {
            this.comprobarMatricula = true; // actualizamos para nuevas acciones
            if (comprobar === false || (this.vermas.nifTerminal != null && this.vermas.nifTerminal !== '')) {
                this._spinnerService.start();
                if ((null === this._loginService.session) || (null === this._loginService.session.usuari)) {
                    this.router.navigate(['/Welcome']);
                } else {
                    if (this.comprobarRequeridos()) {
                        this.vermas.nifSessio = this._loginService.session.nif;
                        this.vermas.paisSessio = this._loginService.session.pais;
                        this._vermasService.crearVermas(this.vermas)
                            .subscribe(
                                updateResponse => {
                                    if (null === updateResponse.id || ((updateResponse.errors != null) &&
                                        (updateResponse.errors.length > 0))) {
                                        this.mensaje = `No ha sido posible el grabado: ${updateResponse.errors}`;
                                        this.display1 = true;
                                    } else {
                                        this.mensajeGrabacion = `Grabado correctamente:`;
                                        this.display = true;
                                        this.enviado = true;
                                        this.vermas.id = updateResponse.id;
                                        //   this._router.navigate(['Vermas']);
                                    }
                                },
                                error => this.errorMessage = <any>error,
                                () => this._spinnerService.stop());
                    } else {
                        this.show();
                        this._spinnerService.stop();
                    }
                }
            } else {
                this._spinnerService.stop();
                this.displayNoTerminal = true;

            }
        } else { // el digigo de la matricula no es valido
            if ((null == this.vermas.containerId) || (this.vermas.containerId == '') || (this.vermas.containerId == undefined)) {
                this.mensaje = 'Faltan datos requeridos: matricula';
                this.show();
            } else {
                this.mensajeContenedorNoValido = ' El digito de control ' +
                    this.vermas.containerId.substring(this.vermas.containerId.length - 1) +
                    ' , según estándar ISO 6346, para este número de contenedor ' +
                    this.vermas.containerId.substring(0, this.vermas.containerId.length - 1) + ' no es válido </br>¿Desea continuar?';
                this.displayMatriculaNoValida = true;
            }
        }
    }
    aceptarEnviar(): void {
        this.displayNoTerminal = false;
        this.comprobarMatricula = false;
        // si ha llegado hasta esta validacion es pq ya ha pasado la de la matricula por tanto no la volvemos a pasar

        if (this.accion === 'ENVIAR') {
            this.enviar(this.modo, false);
        } else if (this.accion === 'CREAR') {
            if (this.view === 'N') {
                this.crear(false);
            } else { this.guardar(false); }
        } else if (this.accion === 'GUARDAR') { this.guardar(false); }
    }
    cancelarEditar(): void {
        this.displayNoTerminal = false;
        this.comprobarMatricula = true;
    }

    aceptarEnviarContenedor(): void {
        this.displayMatriculaNoValida = false;
        this.comprobarMatricula = false;
        if (this.accion === 'ENVIAR') { this.enviar(this.modo, true); } else if (this.accion === 'CREAR') {
            if (this.view === 'N') { this.crear(true); } else { this.guardar(true); }
        } else if (this.accion === 'GUARDAR') { this.guardar(true); }
    }
    cancelarEditarContenedor(): void {
        this.displayMatriculaNoValida = false;
        this.comprobarMatricula = true;
    }
    enviar(modo: string, comprobar: boolean): void {
        this.modo = modo;
        this.accion = 'ENVIAR';
        this.displayNoTerminal = false;
        if (this.comprobarMatricula === false ||
            this._validacionesContenedorService.esDigitoControlContenedorCorrecto(this.vermas.containerId)) {
            this.comprobarMatricula = true; // actualizamos para nuevas acciones
            if (comprobar === false || (this.vermas.nifTerminal != null && this.vermas.nifTerminal !== '')) {

                if (this.comprobarRequeridos()) {
                    if ((null === this._loginService.session) || (null === this._loginService.session.usuari)) {
                        this.router.navigate(['/Welcome']);
                    } else {
                        this._vermasService.getTermEpesoSel(this.vermas.nifTerminal).subscribe(terminales => {
                            console.log(terminales);
                            this.terminalRespuesta = [];
                            this.terminalRespuesta = terminales;
                            if (this.terminalRespuesta.length !== 1) {
                                this.mensajeContenedorNoValido = ' La Terminal ' + this.vermas.nombreTerminal +
                                    'no es una Terminal marítima. Cambie a APM Terminal o BEST Hutchinson , ' +
                                    'según la terminal marítima de destino del contenedor (' + this.vermas.containerId + ').';
                                this.avisoTerFerr = true;
                            } else {
                                this.sendVermas();
                            }
                        });
                    }
                } else {
                    this.show();
                    this._spinnerService.stop();
                }
            } else {
                this.displayNoTerminal = true;
            }

        } else { // el digigo de la matricula no es valido

            if ((null === this.vermas.containerId) || (this.vermas.containerId === '') || (this.vermas.containerId === undefined)) {
                this.mensaje = 'Faltan datos requeridos: matricula';
                this.show();
            } else {
                this.mensajeContenedorNoValido = ' El digito de control ' +
                    this.vermas.containerId.substring(this.vermas.containerId.length - 1) +
                    ' , según estándar ISO 6346, para este número de contenedor ' +
                    this.vermas.containerId.substring(0, this.vermas.containerId.length - 1) +
                    ' no es válido </br>¿Desea continuar?';
                this.displayMatriculaNoValida = true;
            }

        }
    }

    sendVermas(): void {
        this.avisoTerFerr = false;
        this.vermas.nifSessio = this._loginService.session.nif;
        this.vermas.paisSessio = this._loginService.session.pais;
        this.vermas.nomEmpresa = this._loginService.session.nomEmpresa;
        if ((this.view === 'N') && (this.enviado === false)) {
            this._spinnerService.start();
            this._vermasService.crearVermas(this.vermas)
                .subscribe(
                    updateResponse => {
                        if (null === updateResponse.id ||
                            ((updateResponse.errors != null) && (updateResponse.errors.length > 0))) {
                            this.mensaje = `No ha sido posible el grabado: ${updateResponse.errors}`;
                            this.display1 = true;
                        } else {
                            this.vermas.id = updateResponse.id;
                            this._vermasService.sendVermas(this.vermas)
                                .subscribe(
                                    tracking => {
                                        if (null === tracking.numTrk || ((null != tracking.errors) &&
                                            (tracking.errors.length > 0))) {
                                            this.mensaje = `No ha sido posible enviar: ${tracking.errors}`;
                                            this.display1 = true;
                                        } else {
                                            this.track = tracking; this.acciion = 'EN';
                                            this.mensajeConfirmacion = `Mensaje enviado correctamente con número de tracking ${tracking.numTrk}`;
                                            if (this.modo === 'NE') { this.display2 = true; } else {
                                                this._comunService.ponerMensaje(`Mensaje enviado correctamente con número de tracking ${tracking.numTrk}`);
                                                this.onBack();
                                            }
                                        }
                                    },
                                    error => this.errorMessage = <any>error);

                        }
                        this._spinnerService.stop();
                    },
                    error => this.errorMessage = <any>error, () => this._spinnerService.stop());
        } else {
            this._vermasService.actualizaVermas(this.vermas)
                .subscribe(
                    updateResponse => {
                        if (null === updateResponse.id || (updateResponse.errors != null) &&
                            (updateResponse.errors.length > 0)) {
                            this.mensaje = `No ha sido posible el grabado: ${updateResponse.errors}`;
                            this.display1 = true;
                        } else {
                            this._vermasService.sendVermas(this.vermas)
                                .subscribe(
                                    tracking => {
                                        if (null === tracking.numTrk || ((null != tracking.errors) &&
                                            (tracking.errors.length > 0))) {
                                            this.mensaje = `No ha sido posible enviar: ${tracking.errors}`;
                                            this.display1 = true;
                                        } else {
                                            this.track = tracking; this.acciion = 'EN'; this.mensajeConfirmacion =
                                                `Mensaje enviado correctamente con número de tracking ${tracking.numTrk}`;
                                            if (this.modo === 'NE') { this.display2 = true; } else {
                                                this._comunService.ponerMensaje(`Mensaje enviado correctamente con número de tracking ${tracking.numTrk}`);
                                                this.onBack();
                                            }
                                        }
                                    },
                                    error => this.errorMessage = <any>error);
                            //  this._router.navigate(['Vermas']);
                        }
                    }, error => this.errorMessage = <any>error, () => this._spinnerService.stop());
        }
    }

    mostrar(emisorOConsignatario: number): void {
        this.mostra = true;
        this.esEmisorOConsignatario = emisorOConsignatario;
    }
    cerrarPopup(message: string): void {
        this.mostra = false;
    }
    seleccionadoConsignatario(event: IEmisor) {
        if (this.esEmisorOConsignatario === 1) {
            if (event.ActividadEmisor === 'C') {
                this.vermas.Consignatario = event.nombreEmisor;
                this.vermas.nifConsignatario = event.nifEmisor;
            }
            this.vermas.solicitante = event.nombreEmisor;
            this.vermas.nifSolicitante = event.nifEmisor;
        } else {
            this.vermas.Consignatario = event.nombreEmisor;
            this.vermas.nifConsignatario = event.nifEmisor;
        }
        this.cerrarPopup('');
    }
    crearOUpdate(comprobar: boolean) {
        if (this.view === 'N') { this.crear(comprobar); } else { this.guardar(comprobar); }
    }
    aceptarCambioTerminal() {
        this.vermas.nifTerminal = this.nifTerminalR;
        this.displayDialogTerminal = false;
    }

}
