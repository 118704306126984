import { Component } from '@angular/core';
import { LoginService } from './shared/login.service';
import { SpinnerService } from './shared/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app works!';
  Usuario: String;
  Empresa: String;
  pageTitle = 'Listado de pesos verificados';
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this._router.navigate(['/welcome']);
  }
  constructor(private _loginService: LoginService, public _spinnerService: SpinnerService, private _router: Router) {

  }
  mostrar(): boolean {
    if (null != this._loginService.session && null != this._loginService.session.usuari) {
      this.Usuario = this._loginService.session.usuari;
      this.Empresa = this._loginService.session.nomEmpresa;
      return true;
    } else { return false; }
  }
  logout() {
    if (null != this._loginService.session) {
      this._loginService.session = null;

    }
    this._router.navigate(['/welcome']);
  }
}

