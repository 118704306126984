export class Config {

    public static getEnvironmentVariable(value) {
        let environment: string;
        let protocol: string;
        let data = {};
        environment = window.location.hostname;
        protocol = window.location.protocol;
        switch (environment) {
            //                    endPoint: 'http://localhost:8181'
            //                    endPoint: 'http://10.120.1.182:12100'

            case 'localhost':
                data = {
                    endPoint: protocol + "//" + environment +":" + window.location.port,
                    //endPoint2: 'http://reingtest.portic.net/ePesoWeb'
                     endPoint2: protocol + "//" + environment +":" + window.location.port+'/ePesoWeb'
                    // endPoint2: 'http://localhost:8181/ePesoWeb'
                    // endPoint: 'http://10.120.1.182:12100',
                };
                break;
            case '10.120.1.182':
                data = {
                    endPoint: protocol + '//10.120.1.182:12100',
                    endPoint2: 'http://10.120.1.182:12100/ePesoWeb'
                };
                break;
            case 'app-desa.portic.net':
                data = {
                    endPoint: protocol + '//app-desa-portic.net',
                    endPoint2: 'https://app-desa.portic.net/ePesoWeb'
                }
            case 'app-pre.portic.net':
                data = {
                    endPoint: protocol + '//app-pre-portic.net',
                    endPoint2: 'https://app-pre.portic.net/ePesoWeb'
                }
                case 'app-test-portic.net':
                data = {
                    endPoint: protocol + '//app-test-portic.net',
                    endPoint2: 'https://app-test.portic.net/ePesoWeb'
                }
            case 'app-tinglado-portic.net':
                data = {
                    endPoint: protocol + '//app-tinglado-portic.net',
                    endPoint2: 'https://app-tinglado.portic.net/ePesoWeb'
                }
             
            case 'epeso-pre.portic.net':
                data = {
                    endPoint: protocol + '//app-pre.portic.net',
                    endPoint2: 'https://speso-pre.portic.net'
                };
                break;
            case 'epeso-desa.portic.net':
                data = {
                    endPoint: protocol + '//app-desa.portic.net',
                    endPoint2: 'https://speso-desa.portic.net'
                };
             break;
             case 'epeso-tinglado.portic.net':
                data = {
                    endPoint: protocol + '//app-tinglado.portic.net',
                    endPoint2: 'https://speso-tinglado.portic.net'
                };
             break;

            case 'reingtest.portic.net':
                data = {
                    endPoint: protocol + '//reingtest.portic.net',
                    endPoint2: 'http://reingtest.portic.net/ePesoWeb'
                };
                break;
            case 'app.portic.net':
                data = {
                    endPoint: protocol + '//app.portic.net',
                    endPoint2: 'https://speso.portic.net'
                };
                break;
            case 'appdemo.portic.net':
                data = {
                    endPoint: protocol + '//appdemo.portic.net',
                    endPoint2: 'http://spesodemo.portic.net'
                };
                break;
            case 'epeso.portic.net':
                data = {
                    endPoint: protocol + '//app.portic.net',
                    endPoint2: 'https://speso.portic.net'
                };
                break;
            case 'epesodemo.portic.net':
                data = {
                    endPoint: protocol + '//epesodemo.portic.net',
                    endPoint2: 'https://spesodemo.portic.net'
                };
                break;
            default:
                data = {
                    endPoint: protocol + '//app.portic.net',
                    endPoint2: 'https://speso.portic.net'
                };
        }
        return data[value];
    }
}