import { Injectable } from '@angular/core';

@Injectable()
export class ValidacionesContenedorService {

    // El cálculo del dígito verificador para contenedores siguen ISO 6346
    // obtiene el digito de control del contenedor, si el digito no es correcto devulve -1
    obteberDiditoControl(contenedor: string): number {

        const refc = '0123456789A_BCDEFGHIJK_LMNOPQRSTU_VWXYZ';
        let nValor: number;
        let nTotal = 0;
        let nPow2 = 1;

        // tslint:disable-next-line: triple-equals
        if (contenedor.length != 10) { return -1; } // la longitud ha de ser de 10

        for (let n = 0; n < 10; n++) {
            nValor = refc.indexOf(contenedor.substr(n, 1));
            if (nValor < 0) { return -1; }
            nTotal += nValor * nPow2;
            nPow2 *= 2;
        }

        nTotal = nTotal % 11;
        if (nTotal >= 10) { nTotal = 0; }

        return nTotal;

    }

    esDigitoControlContenedorCorrecto(contenedor: string): boolean {

        try {
            let digControlContenedor: number;

            // tslint:disable-next-line: radix
            digControlContenedor = parseInt(contenedor.substring(contenedor.length - 1));

            // tslint:disable-next-line: triple-equals
            if (digControlContenedor != this.obteberDiditoControl(contenedor.substring(0, contenedor.length - 1))) {
                return false;
            } else {
                return true;
            }
        } catch (excep) {
            console.log('esDigitoControlContenedorCorrecto' + excep);
            return false;
        }
    }

    //valida que la longitud de un contenedor no sea mayor que la permitida
    superaLongitudMaximaMatriculaContenedorPortic(contenedor: string): boolean {

        //longitud maxima de un contenedor, para que no de errores al insertar en Crybox
        const longMaxContendor = 15;
        if (contenedor.length > longMaxContendor) {
            return true;
        } else {
            return false;
        }

    }


    //valida que la longitud de un contenedor no sea mayor que la permitida
    superaLongitudMaximaReferenciaContenedorPortic(refcontenedor: string): boolean {

        //longitud maxima de un contenedor, para que no de errores al insertar en Crybox
        const longMaxContendor = 70;
        if (refcontenedor.length > longMaxContendor) {
            return true;
        } else {
            return false;
        }

    }

    //valida que la longitud de un booking no sea mayor que la permitida 
    superaLongitudMaximaBookingPortic(booking: string): boolean {

        //longitud maxima de un contenedor, para que no de errores al insertar en Crybox
        const longMaxBooking = 35;
        if (booking.length > longMaxBooking) {
            return true;
        } else {
            return false;
        }

    }



}

