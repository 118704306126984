import { IResponse } from '../shared/generic';
/* Defines the product entity */
export class IVermas {
    id: number;
    tipo: string;
    NumeroDocumento: string;
    Function: string;
    nifConsignatario: string;
    Consignatario: string;
    Booking: string;
    ReferenciaSolicitud: string;
    containerReference: string;
    containerId: string;
    containerType: string;
    CentroPesador: string;
    Peso: string;
    MetodoPesado: string;
    FechaHora: string;
    Persona: string;
    Bascula: string;
    ReferenciaPesaje: string;
    Estado: string;
    DescEstado: string;
    nifSessio: string;
    paisSessio: string;
    nifSolicitante: string;
    nomEmpresa: string;
    solicitante: string;
    precinto: string;
    agenteprecinto: string;
    fechaHoraModificacion: string;
    errors: string;
    token: string;
    nifTerminal: string;
    nombreTerminal: string;
}
export interface ListVermas extends IResponse {
    lista: IVermas[];
}

