import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../config/config';
import { LoginService } from '../shared/login.service';
import { Payload } from '../shared/payload';
import { ComunService } from './comun.service';
import { IDocumentacion, IListaDocumentacion } from './documentacion';

@Component({
    templateUrl: 'documentacion.component.html'

})
export class DocumentationComponent implements OnInit {
    pageTitle = 'Documentación relacionada';
    lista: IDocumentacion[];
    list: IListaDocumentacion;
    errorMessage: string;
    mostra = false;
    sub: any;
    selectedEnvio: IDocumentacion;
    constructor(private _comunService: ComunService, private _router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService, private router: Router) {

    }

    ngOnInit() {


        this.sub = this.route.params.subscribe(params => {
            const id = +params['id'];
            this.getDocumentacion(id);
        }
        );

    }


    getDocumentacion(id: number) {
        const payload = new Payload<string>();
        payload['usuariSessio'] = this._loginService.session.usuari;
        payload['nifSessio'] = this._loginService.session.nif;
        payload['paisSessio'] = this._loginService.session.pais;
        payload['token'] = this._loginService.session.token;
        payload['id'] = '' + id;
        this._comunService.recuperarDocumentacion(payload)
            .subscribe(
                list => {
                    this.list = list;
                    this.lista = this.list.lista;
                    this.mostra = true;
                },
                error => this.errorMessage = <any>error);
    }

    onBack(): void {
        this._router.navigate(['/vermas']);
    }
    imprimir(): void {
        if ((null == this._loginService.session) || (null == this._loginService.session.usuari)) {
            this.router.navigate(['/welcome']);
        }
        window.open(Config.getEnvironmentVariable('endPoint2') + `/imprimir?num_ref_trk=${this.selectedEnvio.num_ref_trk}`);
    }

}
