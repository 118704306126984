import { Component, OnChanges, Input, OnInit,
    Output, EventEmitter } from '@angular/core';
import { IVermas } from '../vermas/vermas';
import { IEmisor, ListEsmisor} from './emisor';
import { ComunService } from './comun.service';
import { Payload } from './payload';
import { LoginService } from './login.service';

@Component({
  
    selector: 'ai-receptor',
    templateUrl: 'receptor.component.html',
})
export class ReceptorComponent implements OnInit {
    @Input() vermas: IVermas;
    seleccionado: IEmisor;
    lista: IEmisor[];
    list: ListEsmisor;
    tipoEmpresa: string;
    errorMessage: string;
    listFilter: string = '';
    tipo: string;
    @Output() cerrarConsignee: EventEmitter<boolean> =
    new EventEmitter<boolean>();
    @Output() selectedConsigneeClicked: EventEmitter<IEmisor> =
    new EventEmitter<IEmisor>();
    constructor(private _comunService: ComunService, private _loginService: LoginService) {

    }
    seleccion(): void {
        this.seleccionado.ActividadEmisor = this.tipo;
        this.selectedConsigneeClicked.emit(this.seleccionado);
    }
    ngOnInit(): void {
        this.tipo = 'C';
        this.buscar();

    }
    cerrar() {
        this.cerrarConsignee.emit(true);
    }
    buscar() {
        var payload = new Payload<string>();
        payload["usuariSessio"] = this._loginService.session.usuari;
        payload["nifSessio"] = this._loginService.session.nif;
        payload["paisSessio"] = this._loginService.session.pais;
        payload["token"] = this._loginService.session.token;
        payload["pagina"] = "1";
        payload["tipoEmpresa"] = this.tipo;
        payload["filtro"] = this.listFilter;
        this._comunService.obtenerReceptores(payload)
            .subscribe(
            list => {
            this.list = list;
                this.lista = this.list.lista
            },
            error => this.errorMessage = <any>error);

    }
}
