import {Injectable} from '@angular/core';

@Injectable()
export class SpinnerService {
    private spinning = false;

    constructor() {}

    start() {
        this.spinning = true;
    }

    stop() {
        this.spinning = false;
    }
    
    isSpinning() {
        return this.spinning;
    }
    

}