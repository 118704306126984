import {Pipe, PipeTransform} from '@angular/core';
/**
 * The default ISO Date is not parseable by ts compiler or some such.
*/
@Pipe({ name: 'localeDate' })
export class LocaleDate implements PipeTransform {

  transform(date: string): string {

    let d = new Date(date);
    return d.toLocaleString();
    
  }
}