import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IVermas, ListVermas } from './vermas';
import { VermasService } from '../vermas/vermas.service';
import { DataGrid, DataTable } from 'primeng/primeng';
import { Column, Dialog } from 'primeng/primeng';
// import {DocumentationComponent} from '../shared/documentacion.component';
import { Payload } from '../shared/payload';
import { SpinnerService } from '../shared/spinner.service';
import { LoginService } from '../shared/login.service';
import { Tracking } from '../shared/tracking';
import { ComunService } from '../shared/comun.service';
import { IDocumentacion, IListaDocumentacion } from '../shared/documentacion';

@Component({
    templateUrl: 'vermas-list.component.html'
})
export class VermasListComponent implements OnInit {
    pageTitle = 'Lista de Pesos Verificados';
    imageWidth = 50;
    imageMargin = 2;
    showImage = false;
    listFilter = '';
    errorMessage: string;
    lista: IDocumentacion[];
    list: IListaDocumentacion;
    listaV: ListVermas;
    listVermas: IVermas[];
    display = false;
    display1 = false;
    display2 = false;
    mensajeConfirmacion: string;
    mensajeGrabacion: string;
    acciion: String = '';

    pendientes = false;
    finalizados = false;
    selectedVermas: IVermas;
    mostra = false;
    antiguedad = '1';
    track: Tracking;



    constructor(private _vermasService: VermasService, private router: Router,
        private _loginService: LoginService, private _spinnerService: SpinnerService,
        private _comunService: ComunService) {

    }

    aceptar(): void {
        if (null != this._comunService.obtenerMensaje()) {
            this._comunService.limpiarMensaje();
            this.mensajeConfirmacion = '';
            this.display2 = false;
        }

        if (this.acciion === 'EN') {
            this.display2 = false;
        }
        if (this.acciion = 'EL') {
            this.display1 = false;
            this.buscar();
        }
        this.display = false;


    }
    ngOnInit(): void {

        const payload = new Payload<string>();
        this.errorMessage = '';
        if ((null == this._loginService.session) || (null == this._loginService.session.usuari)) {
            this.router.navigate(['/welcome']);
        } else {
            payload['usuariSessio'] = this._loginService.session.usuari;
            payload['nifSessio'] = this._loginService.session.nif;
            payload['paisSessio'] = this._loginService.session.pais;
            payload['token'] = this._loginService.session.token;
            payload['pagina'] = '1';
            payload['busqueda'] = this.antiguedad;
            this._vermasService.getAllVermas(payload)
                .subscribe(
                    vermas => {
                        if (null != vermas.errors && vermas.errors.length > 0) {
                            this.errorMessage = `No ha sido posible cargar el listad ${vermas.errors}`;
                        } else { this.listaV = vermas; this.listVermas = this.listaV.lista; }
                    },
                    error => this.errorMessage = <any>error);
        }
        if (null != this._comunService.obtenerMensaje()) {
            this.mensajeConfirmacion = this._comunService.obtenerMensaje();
            this.display2 = true;
        }


    }
    buscar(): void {
        this.errorMessage = '';
        this.selectedVermas = null;
        if ((null == this._loginService.session) || (this._loginService.session.usuari == null)) {
            this.router.navigate(['/Welcome']);
        } else {
            const payload = new Payload<string>();
            this._spinnerService.start();
            payload['usuariSessio'] = this._loginService.session.usuari;
            payload['nifSessio'] = this._loginService.session.nif;
            payload['paisSessio'] = this._loginService.session.pais;
            payload['token'] = this._loginService.session.token;
            payload['pagina'] = '1';
            payload['busqueda'] = this.antiguedad;
            payload['matricula'] = this.listFilter;
            this._vermasService.getAllVermas(payload)
                .subscribe(
                    vermas => {
                        if (null != vermas.errors && vermas.errors.length > 0) {
                            this.errorMessage = `No ha sido posible cargar el listad ${vermas.errors}`;
                        } else {
                            this.listaV = vermas; this.listVermas = this.listaV.lista;
                        }
                    },
                    error => this.errorMessage = <any>error,
                    () => this._spinnerService.stop());

        }
    }
    pasarADetalle(): void {
        this.router.navigate(['/detail', this.selectedVermas.id]);
    }
    pasarANuevo(): void {
        this.router.navigate(['/detail', '-1']);
    }
    pasarAView(): void {
        this.router.navigate(['/view', this.selectedVermas.id]);
    }
    activarDocumentacion(): void {
        this.router.navigate(['/docu', this.selectedVermas.id]);
    }

    cerrarPopup(): void {
        this.mostra = false;
    }
    enviar(): void {
        this.errorMessage = '';
        if ((null == this._loginService.session) || (this._loginService.session.usuari == null)) {
            this.router.navigate(['/Welcome']);
        } else {
            this._spinnerService.start();
            this.selectedVermas.nifSessio = this._loginService.session.nif;
            this.selectedVermas.paisSessio = this._loginService.session.pais;
            this.selectedVermas.nomEmpresa = this._loginService.session.nomEmpresa;
            this.selectedVermas.Function = '';
            this._vermasService.sendVermas(this.selectedVermas)
                .subscribe(tracking => {
                    if (tracking != null && tracking.errors != null && tracking.errors.length > 0) {
                        this.display1 = true;
                        this.errorMessage = `No ha sido posible enviar: ${tracking.errors}`;

                    } else if (tracking == null || null == tracking.numTrk) {
                        this.display1 = true;
                        this.errorMessage = `No ha sido posible enviar`;
                    } else {
                        this.track = tracking; this.acciion = 'EN';
                        this.mensajeConfirmacion = `Mensaje enviado correctamente con número de trackin ${tracking.numTrk}`;
                        this.display2 = true;
                    }
                },
                    error => this.errorMessage = <any>error,
                    () => this._spinnerService.stop());
        }
    }
    cancelar(): void {
        this.errorMessage = '';
        if ((null == this._loginService.session) || (this._loginService.session.usuari == null)) {
            this.router.navigate(['/Welcome']);
        } else {
            this._spinnerService.start();
            this.selectedVermas.nifSessio = this._loginService.session.nif;
            this.selectedVermas.paisSessio = this._loginService.session.pais;
            this.selectedVermas.Function = '1';
            this._vermasService.cancelarVermas(this.selectedVermas)
                .subscribe(tracking => {
                    if (tracking.errors.length > 0) {
                        this.errorMessage = `No ha sido posible enviar: ${tracking.errors}`;
                        this.display1 = true;
                    } else {
                        this.track = tracking;
                        this.acciion = 'EN';
                        this.mensajeConfirmacion = `Mensaje enviado correctamente con número de trackin ${tracking.numTrk}`;
                        this.display2 = true;
                    }
                },
                    error => { this.errorMessage = <any>error; this._spinnerService.stop(); },
                    () => this._spinnerService.stop());
        }
    }
    eliminarPregunta(): void {

    }
    eliminar(): void {
        this.errorMessage = '';
        if ((null == this._loginService.session) || (this._loginService.session.usuari == null)) {
            this.router.navigate(['/Welcome']);
        } else {
            this._spinnerService.start();
            this.selectedVermas.nifSessio = this._loginService.session.nif;
            this.selectedVermas.paisSessio = this._loginService.session.pais;

            this._vermasService.eliminar(this.selectedVermas).subscribe(updateResponse => {
                if (updateResponse.errors.length > 0) {
                    this.errorMessage = `No ha sido posible el grabado: ${updateResponse.errors}`;
                    this.display1 = true;
                } else {
                    this.mensajeGrabacion = `Eliminado correctamente:`;
                    this.display = true;
                    this.acciion = 'EL';
                    //   this._router.navigate(['Vermas']);
                }
            },
                error => this.errorMessage = <any>error,
                () => this._spinnerService.stop());
        }
    }



    calcularVisibilidad(): boolean {

        if (this._loginService.session.usuari === 'REINGAD') {
            return false;
        }
        if (this.selectedVermas != null) {
            return true;
        } else {
            return false;
        }

    }

    calcularVisibilidadNuevo(): boolean {

        if (this._loginService.session.usuari === 'REINGAD') {
            return false;
        } else {
            return true;
        }

    }

}
