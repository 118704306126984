import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IVermas } from '../vermas/vermas';
import { VermasService } from '../vermas/vermas.service';
import { LoginService } from '../shared/login.service';
import { Payload } from '../shared/payload';

@Component({
    templateUrl: 'vermas-view.component.html'

})
export class VermasViewComponent implements OnInit {
    pageTitle = 'Detalle del Peso verificado';

    vermas: IVermas;
    errorMessage: string;
    view = 'L';
    mostra = false;
    sub: any;
    constructor(private _vermasService: VermasService,
        private _router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService,
        private router: Router) {
    }

    ngOnInit() {
        if (!this.vermas) {

            // this.pageTitle += `: ${id}`;

            // if (this._loginService.session==null){
            //     this.router.navigate(['/Welcome']);
            //  }

            this.sub = this.route.params.subscribe(params => {
                const id = +params['id']; // (+) converts string 'id' to a number
                if (id === -1) {
                    this.view = 'N';
                    this.vermas = new IVermas();
                    this.vermas.MetodoPesado = 'SM1';
                    this.vermas.Function = '9';
                } else { this.getVermasOne(id); }
            }
            );
        }

    }


    getVermasOne(id: number) {
        const payload = new Payload<string>();
        payload['usuariSessio'] = this._loginService.session.usuari;
        payload['nifSessio'] = this._loginService.session.nif;
        payload['paisSessio'] = this._loginService.session.pais;
        payload['token'] = this._loginService.session.token;
        payload['id'] = '' + id;
        this._vermasService.getVermas(payload)
            .subscribe(
                vermas => this.vermas = vermas,
                error => this.errorMessage = <any>error);
    }

    onBack(): void {
        this._router.navigate(['/vermas']);
    }
    edit(): void {
        this.router.navigate(['/detail', this.vermas.id]);
    }


}
